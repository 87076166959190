    var cc = initCookieConsent();

    cc.run({
        current_lang: 'cs',
        autoclear_cookies: true,                   // default: false
        theme_css: 'https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.7.1/dist/cookieconsent.css',  // 🚨 replace with a valid path
        page_scripts: true,                        // default: false

        // delay: 0,                               // default: 0
        auto_language: 'document',               // default: null; could also be 'browser' or 'document'
        // autorun: true,                          // default: true
        // force_consent: false,                   // default: false
        // hide_from_bots: false,                  // default: false
        // remove_cookie_tables: false             // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0

        onFirstAction: function (user_preferences, cookie) {
            // callback triggered only once
        },

        onAccept: function (cookie) {
        },

        onChange: function (cookie, changed_preferences) {
        },

        languages: {
            'cs': {
                consent_modal: {
                    title: 'Používáme cookies!',
                    description: 'Souhlasím s&nbsp;užitím mých osobních údajů k&nbsp;marketingovým účelům za&nbsp;podmínek stanovených v&nbsp;dokumentu dostupném zde: <a href="/dokumenty/informace-o-cookies.pdf" target="_blank" class="cc-link">Informace ke&nbsp;zpracování osobních údajů.</a> Potvrzuji, že&nbsp;jsem se&nbsp;v&nbsp;části <a href="/dokumenty/informace-o-cookies.pdf" target="_blank" class="cc-link">„Práva subjektů údajů“</a> seznámil/a s&nbsp;právy, která&nbsp;ohledně mých osobních údajů mám. <span style="margin-top:10px;display:block;"><button type="button" data-cc="c-settings" class="cc-link">Upravit&nbsp;nastavení</button></span>',
                    primary_btn: {
                        text: 'Potvrdit vše',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Odmítnout vše',
                        role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Předvolby souborů cookie',
                    save_settings_btn: 'Uložit nastavení',
                    accept_all_btn: 'Potvrdit vše',
                    reject_all_btn: 'Odmítnout vše',
                    close_btn_label: 'Zavřít',
                    cookie_table_headers: [
                        {col1: 'Název'},
                        {col2: 'Doména'},
                        {col3: 'Expirace'},
                        {col4: 'Popis'}
                    ],
                    blocks: [
                        {
                            title: 'Používání souborů cookie',
                            description: 'Soubory cookie používám k&nbsp;zajištění základních funkcí webových stránek a&nbsp;ke&nbsp;zlepšení vašeho online zážitku. U&nbsp;každé kategorie si můžete zvolit, zda se&nbsp;chcete přihlásit nebo odhlásit, kdykoli budete chtít. Další podrobnosti týkající se&nbsp;souborů cookie a&nbsp;dalších citlivých údajů naleznete v&nbsp;úplném znění zde: <a href="/dokumenty/informace-o-cookies.pdf" target="_blank" class="cc-link">Zásady užívaní souboru cookies</a>.'
                        }, {
                            title: 'Nezbytné',
                            description: 'Tyto cookies jsou vyžadovány pro správnou funkčnost našich webových stránek a v našem systému je nelze vypnout.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                            },
                            cookie_table: [             // list of all expected cookies
                                {
                                    col1: 'cc_cookie',       // match all cookies starting with "_ga"
                                    col2: '.investujteivy.cz',
                                    col3: '6 měsíců',
                                    col4: 'Uchovává informace o souhlasu s používáním cookies',
                                    is_regex: true
                                }
                            ]


                        }, {
                            title: 'Výkonnostní a analytické',
                            description: 'Tyto soubory cookie umožňují webové stránce zapamatovat si volby, které jste provedli v minulosti.',
                            toggle: {
                                value: 'analytics',     // your cookie category
                                enabled: false,
                                readonly: false
                            },
                            cookie_table: [             // list of all expected cookies
                                {
                                    col1: '_ga',       // match all cookies starting with "_ga"
                                    col2: '.investujteivy.cz',
                                    col3: '2 roky',
                                    col4: 'Tento soubor cookie je&nbsp;nainstalován službou Google Analytics. Soubor cookie se&nbsp;používá k&nbsp;výpočtu údajů o&nbsp;návštěvnících, relacích, kampaních a&nbsp;sledování využití webu pro&nbsp;analytický přehled webu. Cookies ukládají informace anonymně a&nbsp;uložené náhodně vygenerované číslo k&nbsp;identifikaci jedinečných návštěvníků.',
                                    is_regex: true
                                },
                                {
                                    col1: '_gat',
                                    col2: '.investujteivy.cz',
                                    col3: '1 den',
                                    col4: 'Používá se&nbsp;systémem Google Analytics pro&nbsp;regulaci rychlosti zadávání požadavků.',
                                },
                                {
                                    col1: '_gid',
                                    col2: '.investujteivy.cz',
                                    col3: '1 den',
                                    col4: 'Tento soubor cookie je&nbsp;nainstalován službou Google Analytics. Soubor cookie se&nbsp;používá k&nbsp;ukládání informací o&nbsp;tom, jak návštěvníci používají web, a&nbsp;pomáhá při&nbsp;vytváření analytické zprávy o&nbsp;tom, jak web funguje. Shromážděná data včetně počtu návštěvníků, zdroje, odkud pocházejí, a&nbsp;stránek se&nbsp;zobrazují v&nbsp;anonymní podobě.',
                                }
                            ]

                        }
                    ]
                }
            },
            'en': {
                consent_modal: {
                    title: 'We use cookies!',
                    description: 'Colosseum, a.s. uses only cookies and similar technologies to the extent necessary for operation of this website. Colosseum, a.s. asks for your consent to use optional cookies to improve and personalize your experience by collecting analytical data such as number of visits and traffic sources, providing advertising and communicating with third parties. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                    primary_btn: {
                        text: 'Accept all',
                        role: 'accept_all'              // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Reject all',
                        role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                    }
                },
                settings_modal: {
                    title: 'Cookie preferences',
                    save_settings_btn: 'Save settings',
                    accept_all_btn: 'Accept all',
                    reject_all_btn: 'Reject all',
                    close_btn_label: 'Close',
                    cookie_table_headers: [
                        {col1: 'Name'},
                        {col2: 'Domain'},
                        {col3: 'Expiration'},
                        {col4: 'Description'}
                    ],
                    blocks: [
                        {
                            title: 'Cookie usage',
                            description: 'Colosseum, a.s. uses cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/dokumenty/informace-o-cookies.pdf" target="_blank" class="cc-link">Cookie Policy</a>.'
                        }, {
                            title: 'Strictly necessary cookies',
                            description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                            }
                        }, {
                            title: 'Performance and Analytics cookies',
                            description: 'These cookies allow the website to remember the choices you have made in the past',
                            toggle: {
                                value: 'analytics',     // your cookie category
                                enabled: false,
                                readonly: false
                            },
                            cookie_table: [             // list of all expected cookies
                                {
                                    col1: '_ga',       // match all cookies starting with "_ga"
                                    col2: '.investujteivy.cz',
                                    col3: '2 years',
                                    col4: 'This cookie name is associated with Google Universal Analytics - which is a significant update to Google\'s more commonly used analytics service. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session and campaign data for the sites analytics reports.',
                                    is_regex: true
                                },
                                {
                                    col1: '_gid',
                                    col2: '.investujteivy.cz',
                                    col3: '1 day',
                                    col4: '\tThis cookie is set by Google Analytics. It stores and update a unique value for each page visited and is used to count and track pageviews.',
                                }
                            ]
                        }
                    ]
                }
            }


        }
    });

